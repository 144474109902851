import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import "./tailwind.css"

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)


import ApolloClient from "apollo-boost";
const apolloClient = new ApolloClient({
  uri: "https://api-eu-central-1.graphcms.com/v2/cl2xkuhz42kt201z3511nca1t/master"
})

import VueApollo from "vue-apollo";
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

new Vue({
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
