<template>
  <div
    class="z-10 max-w-sm overflow-hidden bg-sunset rounded-xl"
    style="flex: 1 0 16rem"
  >
    <div
      class="relative bg-top bg-cover aspect h-96"
      :style="`background-image:url('${lid.imgUrl}');`"
    >
      <div class="absolute top-0 right-0 w-full h-16"></div>
    </div>
    <div class="flex items-center justify-between px-6 py-4">
      <div class="text-2xl font-bold font-heading text-seabed">{{ lid.name }}</div>
      <div class="ml-4 text-xl font-bold text-ocean">{{ lid.instrument }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lid"]
}
</script>
