<template>
  <div id="app" class="relative selection:bg-sun selection:text-seabed">
    <div
      class="fixed bottom-0 right-0 z-20 justify-end hidden pb-12 pr-12 font-bold transition md:flex text-sunset hover:text-sun"
    >
      <a
        href="#"
        class="flex items-center pb-2 border-b-4 md:text-xl border-sunset hover:border-sun"
        v-scroll-to="'#section0'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 mt-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
        <span class="ml-3">Terug naar de oppervlakte</span>
      </a>
    </div>
    <div class="fixed bottom-0 right-0 z-20 block pb-6 pr-6 md:hidden">
      <a
        href="#"
        class="flex items-center justify-center w-16 h-16 rounded-full md:hidden text-sun bg-seabed bg-opacity-40"
        v-scroll-to="'#section0'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-12 h-12"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 11l5-5m0 0l5 5m-5-5v12"
          />
        </svg>
      </a>
    </div>
    <header
      class="items-center px-5 py-4 text-lg font-bold md:hidden bg-ocean"
      v-if="
        paginaText &&
          paginaText.announcement !== '' &&
          paginaText.announcement.html !== '<p></p>'
      "
    >
      <span class="inline-flex items-center text-sm font-bold text-white">
        <span
          class="rich-text announcement"
          v-html="paginaText.announcement.html"
        >
        </span>
      </span>
    </header>
    <section
      id="section0"
      class="relative z-20 min-h-screen px-8 overflow-hidden transition-all pb-52 md:px-16"
      :class="{
        'bg-sunset text-seabed': time === 'day',
        'bg-seabed text-white': time === 'night',
      }"
    >
      <div class="relative z-10 py-12 pointer-events-none">
        <h1 class="text-5xl font-bold leading-normal font-heading">Sloops</h1>
        <p class="mt-2 text-2xl font-bold">
          Varen door de
          <span
            class="cursor-pointer pointer-events-auto select-none"
            id="genreText"
            @click="handleGenreTextClick"
            >funk</span
          >
        </p>
      </div>
      <div
        class="hidden mt-6 md:block"
        v-if="
          paginaText &&
            paginaText.announcement !== '' &&
            paginaText.announcement.html !== '<p></p>'
        "
      >
        <div
          class="inline-flex items-center px-5 py-3 text-lg font-bold text-white rounded-full bg-ocean"
        >
          <svg
            class="w-5"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M200.7 128C270.7 128 337.9 100.2 387.4 50.68L425.4 12.69C445.5-7.472 480 6.805 480 35.31V179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V444.7C480 473.2 445.5 487.5 425.4 467.3L387.4 429.3C339.6 381.5 275.4 353.1 208 352.1V456C208 486.9 182.9 512 152 512H120C89.07 512 64 486.9 64 456V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128L200.7 128zM432 73.94L421.3 84.62C364.5 141.4 288.1 174 208 175.9V304.1C288.1 305.1 364.5 338.6 421.3 395.4L432 406.1V73.94zM112 456C112 460.4 115.6 464 120 464H152C156.4 464 160 460.4 160 456V352H112V456zM48 192V288C48 296.8 55.16 303.1 64 303.1H160V176H64C55.16 176 48 183.2 48 192V192z"
            />
          </svg>
          <div
            class="ml-3 rich-text announcement"
            v-html="paginaText.announcement.html"
          ></div>
        </div>
      </div>
      <div class="space-y-8 md:mt-12">
        <div v-for="link in links" :key="link.label">
          <a
            class="text-xl font-bold transition border-b-4 md:text-2xl"
            :class="{
              'text-ocean border-ocean hover:border-seabed hover:text-seabed':
                time === 'day',
              'text-sunset border-sunset hover:border-sun hover:text-sun':
                time === 'night',
            }"
            :href="link.href"
            v-scroll-to="link.sectionId"
            >{{ link.label }}</a
          >
        </div>
      </div>

      <div
        class="absolute z-0 w-40 h-40 transition-opacity rounded-full opacity-0 cursor-pointer md:w-32 md:h-32 -right-10 -top-10 bg-sun md:top-16 md:right-24"
        @click="flipDayNight"
        :class="{ 'opacity-100': time === 'day' }"
      ></div>

      <div
        class="absolute z-0 w-40 h-40 overflow-hidden transition-opacity bg-white rounded-full opacity-0 cursor-pointer md:w-32 md:h-32 -right-10 -top-10 md:top-16 md:right-24"
        @click="flipDayNight"
        :class="{ 'opacity-100': time === 'night' }"
      ></div>

      <div class="absolute bottom-0 left-0 right-0 z-20">
        <div class="flex justify-end relative">
          <img
            id="sloop"
            class="h-16 transition absolute bottom-0 left-0 cursor-pointer"
            :class="{ invert: time === 'night' }"
            :style="
              `transition-duration: ${sloopTransitionDuration}ms; transform: translateX(${sloopLeft}px)`
            "
            src="/img/sloop 2.svg"
            alt="Sloop"
            @click="handleSloopClick"
          />
        </div>
        <div class="h-24 md:h-48 bg-ocean"></div>
      </div>
    </section>

    <div class="relative bg-gradient-to-t from-seabed to-ocean">
      <section
        id="section1"
        class="min-h-screen px-8 text-white bg-bottom bg-no-repeat bg-cover md:px-16"
        style="background-image: url('/img/bubbles.svg')"
      >
        <div class="py-12">
          <h2
            class="text-4xl font-bold leading-normal md:text-5xl font-heading"
          >
            Huh, wie?
          </h2>
          <div v-if="paginaText && paginaText.huhWieAfbeelding">
            <img
              v-if="paginaText.huhWieAfbeelding.url"
              class="w-full max-w-2xl mt-12"
              :src="paginaText.huhWieAfbeelding.url"
              alt="Omslagfoto"
            />
          </div>
          <div
            class="mt-6 rich-text"
            v-if="paginaText && paginaText.huhWieText"
          >
            <div
              v-if="paginaText.huhWieText"
              v-html="paginaText.huhWieText.html"
            ></div>
          </div>
        </div>
        <div class="flex flex-wrap justify-center gap-6 mt-6">
          <band-member-card v-for="lid in leute" :key="lid.name" :lid="lid" />
        </div>
      </section>
      <section
        id="section2"
        class="min-h-screen px-8 text-white bg-bottom bg-no-repeat bg-cover md:px-16"
        style="background-image: url('/img/bubbles.svg')"
      >
        <div class="py-12">
          <h2
            class="mt-12 text-4xl font-bold leading-normal md:text-5xl font-heading"
          >
            Waar en wanneer spelen ze?
          </h2>
          <div class="mt-10 md:mt-20">
            <div class="space-y-10 md:hidden">
              <div v-for="event in sanitizedEvents" :key="event.waar">
                <div class="font-bold text-sunset">
                  <span>{{
                    event.wanneer.toLocaleString("nl-NL", {
                      weekday: "short",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  }}</span>
                  <span>{{ event.hoeLaat ? ` om ${event.hoeLaat}` : "" }}</span>
                </div>
                <div class="mt-1.5 text-xl font-bold">
                  <a
                    class="flex items-center underline text-sun"
                    v-if="event.link"
                    :href="event.link"
                    target="_blank"
                    rel="noopener"
                    >{{ event.waar }}</a
                  >
                  <span v-else>{{ event.waar }}</span>
                </div>
              </div>
            </div>
            <table class="hidden md:block">
              <tr class="border-b-2">
                <th class="pb-2 pr-24 text-2xl text-left">Wanneer</th>
                <th class="pb-2 pr-24 text-2xl text-left">Hoe laat</th>
                <th class="pb-2 text-2xl text-left">Waar</th>
              </tr>
              <tr
                class="text-xl"
                v-for="event in sanitizedEvents"
                :key="event.waar"
              >
                <td class="py-4 pr-24 font-bold first-letter:uppercase">
                  {{
                    event.wanneer.toLocaleString("nl-NL", {
                      weekday: "short",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  }}
                </td>
                <td class="py-4 pr-24 font-bold">
                  {{ event.hoeLaat ? event.hoeLaat : "-" }}
                </td>
                <td class="py-4 font-bold">
                  <a
                    class="flex items-center underline text-sun"
                    v-if="event.link"
                    :href="event.link"
                    target="_blank"
                    rel="noopener"
                    >{{ event.waar }}</a
                  >
                  <span v-else>{{ event.waar }}</span>
                </td>
              </tr>
            </table>

            <div class="mt-4 font-bold" v-if="sanitizedEvents.length === 0">
              Op dit moment staan er (nog) geen optredens op de planning.
            </div>
          </div>
        </div>
      </section>
      <section
        id="section3"
        class="min-h-screen px-8 text-white bg-bottom bg-no-repeat bg-cover md:px-16"
        style="background-image: url('/img/bubbles.svg')"
      >
        <div class="py-12">
          <h2
            class="mt-12 text-4xl font-bold leading-normal md:text-5xl font-heading"
          >
            En wat spelen ze dan?
          </h2>
          <div class="mt-10 flex flex-col md:flex-row gap-6">
            <div class="max-w-xs w-full">
              <iframe
                style="border-radius:12px"
                src="https://open.spotify.com/embed/album/7a6kkWoZhzjhyvh0clPY4r?utm_source=generator&theme=0"
                width="100%"
                height="380"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              ></iframe>
            </div>
            <div class="max-w-xs w-full">
              <iframe
                style="border-radius:12px"
                src="https://open.spotify.com/embed/album/1JKbPAASYPtWtDAYgkBkoV?utm_source=generator&theme=0"
                width="100%"
                height="380"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section4"
        class="min-h-screen px-8 text-white bg-bottom bg-no-repeat bg-cover md:px-16"
        style="background-image: url('/img/seabed2.svg')"
      >
        <div class="py-12">
          <h2
            class="mt-12 text-4xl font-bold leading-normal md:text-5xl font-heading"
          >
            Hoe kan ik ze bereiken?
          </h2>
          <p class="mt-12 text-xl font-bold md:text-2xl">Neem contact op via</p>
          <p class="flex items-center mt-6">
            <svg
              class="w-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M207.8 20.73c-93.45 18.32-168.7 93.66-187 187.1c-27.64 140.9 68.65 266.2 199.1 285.1c19.01 2.888 36.17-12.26 36.17-31.49l.0001-.6631c0-15.74-11.44-28.88-26.84-31.24c-84.35-12.98-149.2-86.13-149.2-174.2c0-102.9 88.61-185.5 193.4-175.4c91.54 8.869 158.6 91.25 158.6 183.2l0 16.16c0 22.09-17.94 40.05-40 40.05s-40.01-17.96-40.01-40.05v-120.1c0-8.847-7.161-16.02-16.01-16.02l-31.98 .0036c-7.299 0-13.2 4.992-15.12 11.68c-24.85-12.15-54.24-16.38-86.06-5.106c-38.75 13.73-68.12 48.91-73.72 89.64c-9.483 69.01 43.81 128 110.9 128c26.44 0 50.43-9.544 69.59-24.88c24 31.3 65.23 48.69 109.4 37.49C465.2 369.3 496 324.1 495.1 277.2V256.3C495.1 107.1 361.2-9.332 207.8 20.73zM239.1 304.3c-26.47 0-48-21.56-48-48.05s21.53-48.05 48-48.05s48 21.56 48 48.05S266.5 304.3 239.1 304.3z"
              />
            </svg>
            <a
              href="mailto:info@sloops.nl"
              class="inline-block ml-3 text-2xl font-bold transition border-b-4 text-sunset border-sunset hover:border-sun hover:text-sun"
              >info@sloops.nl
            </a>
          </p>
          <p class="flex items-center mt-6">
            <svg
              class="w-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
              />
            </svg>
            <a
              target="_blank"
              rel="noopener"
              href="https://www.facebook.com/sloopsmusic/"
              class="inline-block ml-3 text-2xl font-bold transition border-b-4 text-sunset border-sunset hover:border-sun hover:text-sun"
              >sloopsmusic</a
            >
          </p>
          <p class="flex items-center mt-6">
            <svg
              class="w-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
            <a
              target="_blank"
              rel="noopener"
              href="https://www.instagram.com/sloopsmusic/"
              class="inline-block ml-3 text-2xl font-bold transition border-b-4 text-sunset border-sunset hover:border-sun hover:text-sun"
              >sloopsmusic</a
            >
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

import Typewriter from "typewriter-effect/dist/core";

import BandMemberCard from "./components/BandMemberCard.vue";

export default {
  name: "App",
  components: {
    BandMemberCard,
  },
  apollo: {
    paginaText: gql`
      query {
        paginaText(where: { id: "cl2xmf9g2gja40eukor3tronk" }) {
          announcement {
            html
          }
          huhWieText {
            html
          }
          huhWieAfbeelding {
            url
          }
        }
      }
    `,
    events: gql`
      query {
        events {
          id
          waar
          wanneer
          hoeLaat
          link
        }
      }
    `,
  },
  data() {
    return {
      typewriter: null,
      time: "day",
      genreTextClickedCount: 0,
      sloopLeft: 64,
      sloopTransitionDuration: 500,
      sloopIsResetting: false,
      leute: [
        {
          opened: false,
          name: "Merel",
          imgUrl: "/img/merel2.jpg",
          instrument: "Zang",
        },
        {
          opened: true,
          name: "Puck",
          imgUrl: "/img/puck2.jpg",
          instrument: "Toetsen",
        },
        {
          opened: false,
          name: "Simon",
          imgUrl: "/img/simon2.jpg",
          instrument: "Gitaar",
        },
        {
          opened: false,
          name: "Freek",
          imgUrl: "/img/freek2.jpg",
          instrument: "Drums",
        },
      ],
      links: [
        {
          label: "Huh, wie?",
          href: "#",
          sectionId: "#section1",
        },
        {
          label: "Waar en wanneer spelen ze?",
          href: "#",
          sectionId: "#section2",
        },
        {
          label: "En wat spelen ze dan?",
          href: "#",
          sectionId: "#section3",
        },
        {
          label: "Hoe kan ik ze bereiken?",
          href: "#",
          sectionId: "#section4",
        },
      ],
    };
  },
  computed: {
    sanitizedEvents() {
      if (this.events) {
        let sanitizedEvents = [...this.events];
        sanitizedEvents = sanitizedEvents.map(event => {
          return { ...event, wanneer: new Date(event.wanneer) };
        });
        sanitizedEvents = sanitizedEvents.filter(event => {
          return event.wanneer > new Date();
        });
        sanitizedEvents.sort((a, b) => {
          if (a.wanneer < b.wanneer) {
            return -1;
          } else if (a.wanneer > b.wanneer) {
            return 1;
          } else {
            return 0;
          }
        });
        return sanitizedEvents;
      } else {
        return [];
      }
    },
  },
  methods: {
    flipDayNight() {
      if (this.time === "day") {
        this.time = "night";
      } else {
        this.time = "day";
      }
    },
    handleGenreTextClick() {
      this.genreTextClickedCount++;
      switch (this.genreTextClickedCount) {
        case 1:
          this.typewriter
            .deleteAll()
            .typeString("groove")
            .start();
          break;
        case 2:
          this.typewriter
            .deleteAll()
            .typeString("hip-hop")
            .start();
          break;
        case 3:
          this.typewriter
            .deleteAll()
            .typeString("rock")
            .start();
          break;
        case 4:
          this.typewriter
            .deleteAll()
            .typeString("bleu")
            .pauseFor(250)
            .deleteChars(2)
            .typeString("ues")
            .start();
          break;
        case 5:
          this.typewriter
            .deleteAll()
            .start()
            .typeString("jazz")
            .start();
          break;
        default:
          this.typewriter
            .deleteAll()
            .typeString("muziek")
            .start();
          break;
      }
    },
    handleSloopClick() {
      const sloop = document.querySelector("#sloop");
      if (!sloop) return;
      this.sloopLeft += 96;
    },
  },
  watch: {
    sloopLeft() {
      const sloop = document.querySelector("#sloop");
      if (!sloop || this.sloopIsResetting) return;
      // wait half a second
      setTimeout(() => {
        const rect = sloop.getBoundingClientRect();
        const isOutOfViewport = rect.left > window.innerWidth - 50;
        if (isOutOfViewport) {
          this.sloopIsResetting = true;
          this.sloopLeft = window.innerWidth + 150;
          setTimeout(() => {
            this.sloopTransitionDuration = 0;
            this.sloopLeft = -150;
            setTimeout(() => {
              this.sloopTransitionDuration = 500;
              this.sloopLeft = 64;
              this.sloopIsResetting = false;
            }, 1000);
          }, 500);
        }
      }, 500);
    },
  },
  mounted() {
    this.typewriter = new Typewriter(document.querySelector("#genreText"), {
      strings: "funk",
      cursor: "",
      autoStart: true,
    });
  },
};
</script>
